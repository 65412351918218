import {
  IAccountCGDashboard,
  IAccountCG,
  IAccountCGTransactionItem,
  IAccountCNG,
  IAccountCNGStatusForm,
  IAccountCGTransactionForm,
  IAccountCNGDashboard,
  IAccountCNGStatusItem,
  IAccountCNGChartData,
  IAccountCGChartData,
} from './Types';
import { FetchProvider } from '../../providers/FetchProvider';

type InputGetAccountCg = {
  limit: number;
  offset: number;
  account_cg_id: number;
};

const getAllAccountCg = async ({
  limit,
}: {
  limit: number;
}): Promise<{
  accountDataList: IAccountCG[];
  globalDashboardData: IAccountCGDashboard;
  globalChartData: IAccountCGChartData[];
}> => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  return await FetchProvider.fetch(`/investissement/cg?${params}`);
};

const getAllAccountCng = async ({
  limit,
}: {
  limit: number;
}): Promise<{
  accountDataList: IAccountCNG[];
  globalDashboardData: IAccountCNGDashboard;
  globalChartData: IAccountCNGChartData;
}> => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  return await FetchProvider.fetch(`/investissement/cng?${params}`);
};

const getAccountCgTransactionList = async ({
  limit,
  offset,
  account_cg_id,
}: InputGetAccountCg): Promise<{
  rows: IAccountCGTransactionItem[];
}> => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());

  return await FetchProvider.fetch(`/investissement/cg/${account_cg_id}/transaction?${params}`);
};

const getAccountCngStatusList = async ({
  limit,
  offset,
  account_cng_id,
}: {
  limit: number;
  offset: number;
  account_cng_id: number;
}): Promise<{
  rows: IAccountCNGStatusItem[];
}> => {
  //await new Promise((r) => setTimeout(r, 2000));
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());

  return await FetchProvider.fetch(`/investissement/cng/${account_cng_id}/status?${params}`);
};

const addOrUpdateTransaction = async ({
  transaction,
  offset,
  limit,
}: {
  transaction: IAccountCGTransactionForm;
  offset: number;
  limit: number;
}): Promise<{
  success: boolean;
  message: string;
  data: {
    accountData: IAccountCG;
    globalDashboardData: IAccountCGDashboard;
  };
}> => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());

  if (transaction.account_cg_transaction_id) {
    return await FetchProvider.fetch(
      `/investissement/cg/transaction/${transaction.account_cg_transaction_id}?${params}`,
      {
        method: 'PUT',
        body: JSON.stringify(transaction),
      }
    );
  } else {
    return await FetchProvider.fetch(`/investissement/cg/transaction?${params}`, {
      method: 'POST',
      body: JSON.stringify(transaction),
    });
  }
};

const addOrUpdateAccountStatus = async ({
  status,
  offset,
  limit,
}: {
  status: IAccountCNGStatusForm;
  offset: number;
  limit: number;
}): Promise<{
  success: boolean;
  message: string;
  data: {
    accountData: IAccountCNG;
    globalDashboardData: IAccountCNGDashboard;
    globalChartData: IAccountCNGChartData;
  };
}> => {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());

  if (status.account_cng_status_id) {
    return await FetchProvider.fetch(`/investissement/cng/status/${status.account_cng_status_id}?${params}`, {
      method: 'PUT',
      body: JSON.stringify(status),
    });
  } else {
    return await FetchProvider.fetch(`/investissement/cng/status?${params}`, {
      method: 'POST',
      body: JSON.stringify(status),
    });
  }
};

const ApiProvider = {
  getAccountCgTransactionList,
  getAccountCngStatusList,
  addOrUpdateTransaction,
  addOrUpdateAccountStatus,
  getAllAccountCng,
  getAllAccountCg,
};

export default ApiProvider;
