import { Alert, Col, Row } from 'react-bootstrap';
import { CURRENCY_EURO_FORMAT } from '../../../Types';
import { useCallback, useEffect, useRef, useState } from 'react';
import KPI from './KPI';
import TickerCard from './TickerCard';
import ApiProvider from '../ApiProvider';
import { CryptoDashboard, CryptoKPI } from '../Types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CryptoTransactionTable from './CryptoTransactionTable';

export type TickerData = {
  price: number;
  lowLast24h: number;
  highLast24h: number;
  openPriceLast24h: number;
  variation: number;
  lastUpdate: Date;
};

type State = {
  tickerData: {
    [key: string]: TickerData;
  };
  cryptoData?: CryptoDashboard;
  cryptoKPI?: CryptoKPI;
  error: string | null;
};

const TITLE_DATE_FORMAT = Intl.DateTimeFormat('fr-FR', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

const initialState: State = {
  tickerData: {},
  error: null,
};

export const PAGE_SIZE = 10;

function Crypto() {
  const { page } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const [state, setState] = useState<State>(initialState);

  const refWS = useRef<WebSocket>();

  const offset = page ? (Number(page) - 1) * PAGE_SIZE : 0;

  useEffect(() => {
    ApiProvider.getCryptoSummary({
      limit: PAGE_SIZE,
      offset: offset,
    })
      .then((data) => {
        setState((prevState) => {
          if (!prevState.cryptoData) {
            initiateKrakenWebSocket(data);
          }

          return {
            ...prevState,
            cryptoData: data,
          };
        });
      })
      .catch((error: Error) => {
        setState({ error: error.message, tickerData: {} });
      });
  }, [location]);

  const initiateKrakenWebSocket = useCallback((data: CryptoDashboard) => {
    refWS.current = new WebSocket('wss://ws.kraken.com');

    refWS.current.onopen = (_) => {
      refWS.current?.send(
        JSON.stringify({
          event: 'subscribe',
          pair: data.crypto.map((item) => item.pair),
          subscription: {
            name: 'ticker',
          },
        })
      );
    };

    refWS.current.onmessage = function (event) {
      const data = JSON.parse(event.data);

      if (data.event === undefined) {
        setState((prevState) => {
          const newTickerData = {
            ...prevState.tickerData,
            [data[3]]: {
              price: Number(data[1]['c'][0]),
              lowLast24h: Number(data[1]['l'][1]),
              highLast24h: Number(data[1]['h'][1]),
              openPriceLast24h: Number(data[1]['o'][1]),
              variation: (Number(data[1]['c'][0]) - Number(data[1]['o'][1])) / Number(data[1]['o'][1]),
              lastUpdate: new Date(),
            },
          };

          return {
            ...prevState,
            tickerData: newTickerData,
          };
        });
      }
    };
  }, []);

  useEffect(() => {
    const listener = (_: any) => {
      if (document.visibilityState === 'visible') {
        if (refWS.current?.readyState === WebSocket.CLOSED) {
          console.log("it's closed");
        }
      }
    };

    window.addEventListener('visibilitychange', listener);

    return () => {
      window.removeEventListener('visibilitychange', listener);
    };
  }, []);

  useEffect(() => {
    if (state.cryptoData && Object.keys(state.tickerData).length === state.cryptoData.crypto.length) {
      const newTotalCurrentValue =
        state.cryptoData.crypto.reduce((accumulator, currentValue) => {
          const data = state.tickerData[currentValue.pair];

          if (data) {
            return accumulator + currentValue.value * data.price;
          } else {
            return accumulator + 0;
          }
        }, 0) + state.cryptoData.cash;

      const newTotalInvestedAmount =
        state.cryptoData.crypto.reduce((acc, curr) => parseFloat(curr.total_invested_amount) + acc, 0) +
        state.cryptoData.cash;

      const newVariation = newTotalCurrentValue - newTotalInvestedAmount;
      const newVariationPercentage = newVariation / newTotalInvestedAmount;

      setState((prevState) => {
        return {
          ...prevState,
          cryptoKPI: {
            totalCurrentValue: newTotalCurrentValue,
            totalInvestedAmount: newTotalInvestedAmount,
            variation: newVariation,
            variationPercentage: newVariationPercentage,
          },
        };
      });
    }
  }, [state.cryptoData, state.tickerData]);

  useEffect(() => {
    if (state.cryptoKPI?.totalCurrentValue) {
      document.title = `${TITLE_DATE_FORMAT.format(new Date())} - ${CURRENCY_EURO_FORMAT.format(state.cryptoKPI.totalCurrentValue)}`;
    }
  }, [state.cryptoKPI?.totalCurrentValue]);

  const onPaginate = useCallback(async ({ nextPage }: { nextPage: number }) => {
    setState((prevState) => {
      return {
        ...prevState,
        cryptoData: {
          ...prevState.cryptoData!,
          cryptoTransaction: null,
        },
      };
    });

    navigate(`/crypto${nextPage ? '/page/' + nextPage : ''}`);
  }, []);

  if (state.error) {
    return (
      <Alert className="mb-0" variant="danger">
        {state.error}
      </Alert>
    );
  }

  return (
    <>
      <Row className="mb-3">
        <Col>
          <h1>Crypto</h1>
        </Col>
      </Row>
      {state.cryptoKPI ? <KPI {...state.cryptoKPI} /> : <KPI.Placeholder />}
      {state.cryptoData?.crypto.map((item) => {
        const data = state.tickerData[item.pair];
        if (data) {
          return <TickerCard key={item.pair} tickerData={data} cryptoData={item} />;
        } else {
          return <TickerCard.Placeholder key={item.pair} />;
        }
      })}
      {state.cryptoData?.cash && (
        <>
          <Row className="gy-4 mb-5">
            <Col>
              <div className={`p-3 rounded-3 text-primary-emphasis bg-primary-subtle`}>
                <div className="hstack gap-3">
                  <div>
                    <h2 className="mb-0">EURO</h2>
                    <div className="d-md-none mt-2">
                      <h4 className="mb-0">{CURRENCY_EURO_FORMAT.format(state.cryptoData.cash)}</h4>
                    </div>
                  </div>
                  <div className="ms-auto text-end d-none d-md-block">
                    <h3 className="mb-0">{CURRENCY_EURO_FORMAT.format(state.cryptoData.cash)}</h3>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {state.cryptoData?.cryptoTransaction ? (
        <CryptoTransactionTable tableData={state.cryptoData!.cryptoTransaction} onPaginate={onPaginate} />
      ) : (
        <CryptoTransactionTable.Placeholder />
      )}
    </>
  );
}

export default Crypto;
