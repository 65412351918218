import { Button, Col, Pagination, Placeholder, Row, Table } from 'react-bootstrap';
import { IAccountCNG } from '../../../Types';
import { CURRENCY_EURO_FORMAT, PERCENTAGE_FORMAT } from '../../../../../Types';
import { DEFAULT_TABLE_SIZE, FormState } from '..';
import { addWeeksToDate, getRandom } from '../../../../../Helpers';

type Props = {
  account: IAccountCNG;
  offset: number;
  isLoading: boolean;
  onPaginatePrev: () => void;
  onPaginateNext: () => void;
  onEditHandler: ({}: FormState) => void;
  updateAccountCallback: (updatedAccount: IAccountCNG) => void;
};

function AccountTable({
  offset,
  isLoading,
  onPaginatePrev,
  onPaginateNext,
  account,
  onEditHandler,
  updateAccountCallback,
}: Props) {
  if (isLoading) {
    return <ComponentPlaceholder />;
  }

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center align-items-center">
          <p className="mb-0">
            {offset + 1} à{' '}
            {Math.min(
              offset +
                (DEFAULT_TABLE_SIZE > (account.rows?.length || 0) ? account.rows?.length || 0 : DEFAULT_TABLE_SIZE),
              account.total_count
            )}{' '}
            sur {account.total_count}
          </p>
        </Col>
        <Col></Col>
        <Col className="d-flex justify-content-end">
          <Pagination className="mb-0">
            <Pagination.Prev disabled={offset === 0} onClick={() => onPaginatePrev()} />
            <Pagination.Next
              disabled={account.total_count <= offset + DEFAULT_TABLE_SIZE}
              onClick={() => onPaginateNext()}
            />
          </Pagination>
        </Col>
      </Row>
      <Table className="text-center" striped responsive>
        <thead>
          <tr>
            <th style={{ width: '150px' }}>Date</th>
            <th style={{ width: '150px' }}>Situation</th>
            <th style={{ width: '150px' }}>Montant Investi</th>
            <th style={{ width: '150px' }}>Variation</th>
            <th style={{ width: '100px' }}></th>
          </tr>
        </thead>
        <tbody>
          {account.rows.length > 0 ? (
            account.rows.map((statusItem) => {
              const variation = (statusItem.current_value - statusItem.invested_amount) / statusItem.invested_amount;

              console.log(addWeeksToDate(new Date(statusItem.status_date), 2));

              const isDisabled = new Date() > addWeeksToDate(new Date(statusItem.status_date), 2);

              return (
                <tr className="align-middle" key={statusItem.account_cng_status_id}>
                  <td>{new Intl.DateTimeFormat('fr-FR').format(new Date(statusItem.status_date))}</td>
                  <td className="text-primary">{CURRENCY_EURO_FORMAT.format(statusItem.current_value)}</td>
                  <td className="text-info">{CURRENCY_EURO_FORMAT.format(statusItem.invested_amount)}</td>
                  <td className={`${variation > 0 ? 'text-success' : 'text-danger'}`}>
                    {PERCENTAGE_FORMAT.format(variation)}
                  </td>
                  <td>
                    <div className="d-grid">
                      <Button
                        className="btn-block"
                        disabled={isDisabled}
                        variant="warning"
                        onClick={() => {
                          onEditHandler({
                            statusToAddEdit: statusItem,
                            selectedAccount: account,
                            selectedOffset: offset,
                            updateAccountCallback,
                          });
                        }}
                      >
                        Modifier
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5}>Pas de transaction</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

function ComponentPlaceholder() {
  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center">
          <Placeholder size="sm" xs={6} />
        </Col>
        <Col md={6}></Col>
        <Col className="d-flex justify-content-end">
          <Placeholder.Button variant="secondary" xs={3} />
        </Col>
      </Row>
      <Table className="text-center" striped responsive>
        <thead>
          <tr>
            <th style={{ width: '150px' }}>Date</th>
            <th style={{ width: '150px' }}>Situation</th>
            <th style={{ width: '150px' }}>Montant Investi</th>
            <th style={{ width: '150px' }}>Variation</th>
            <th style={{ width: '100px' }}></th>
          </tr>
        </thead>
        <tbody>
          {Array.from(Array(DEFAULT_TABLE_SIZE).keys()).map((_, index) => (
            <tr className="align-middle" key={index}>
              <td>
                <Placeholder xs={getRandom(6, 12)} />
              </td>
              <td>
                <Placeholder xs={getRandom(6, 12)} />
              </td>
              <td>
                <Placeholder xs={getRandom(6, 12)} />
              </td>
              <td>
                <Placeholder xs={getRandom(6, 12)} />
              </td>
              <td>
                <Placeholder.Button variant="warning" xs={8} aria-hidden="true" />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

AccountTable.Placeholder = ComponentPlaceholder;

export default AccountTable;
