import { Button, Card, Col, Placeholder, Row } from 'react-bootstrap';
import { IAccountCG } from '../../../Types';
import AccountTable from './AccountTable';
import AccountKPI from './AccountKPI';
import { useCallback, useReducer } from 'react';
import { DEFAULT_TABLE_SIZE, FormState } from '..';
import ApiProvider from '../../../ApiProvider';

type Props = {
  account: IAccountCG;
  onAddHandler: ({}: FormState) => void;
  onEditHandler: ({}: FormState) => void;
};

type State = {
  account: IAccountCG;
  offset: number;
  isLoading: boolean;
};

function AccountCard({ account, onAddHandler, onEditHandler }: Props) {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {
      account: account,
      offset: 0,
      isLoading: false,
    }
  );

  const updateAccountCallback = useCallback((updatedAccount: IAccountCG) => {
    setState({ account: updatedAccount });
  }, []);

  const onPaginateNext = useCallback(() => {
    const newOffset = state.offset + DEFAULT_TABLE_SIZE;

    setState({ isLoading: true });

    ApiProvider.getAccountCgTransactionList({
      limit: DEFAULT_TABLE_SIZE,
      offset: newOffset,
      account_cg_id: state.account.account_cg_id,
    })
      .then((data) => {
        setState({
          account: {
            ...state.account,
            rows: data.rows,
          },
          offset: newOffset,
        });
      })
      .finally(() => {
        setState({ isLoading: false });
      });
  }, [state.offset, state.account]);

  const onPaginatePrev = useCallback(() => {
    const newOffset = state.offset - DEFAULT_TABLE_SIZE;
    setState({ isLoading: true });
    ApiProvider.getAccountCgTransactionList({
      limit: DEFAULT_TABLE_SIZE,
      offset: newOffset,
      account_cg_id: account.account_cg_id,
    })
      .then((data) => {
        setState({
          account: {
            ...state.account,
            rows: data.rows,
          },
          offset: newOffset,
        });
      })
      .finally(() => {
        setState({ isLoading: false });
      });
  }, [state.offset, state.account]);

  return (
    <Row>
      <Col>
        <Card className="mb-2">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>{state.account.name}</div>
              <div className="">
                <Button
                  onClick={() => {
                    onAddHandler({
                      selectedAccount: state.account,
                      selectedOffset: state.offset,
                      updateAccountCallback,
                    });
                  }}
                >
                  Ajouter
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <AccountKPI account={state.account} />
            <AccountTable
              offset={state.offset}
              isLoading={state.isLoading}
              account={state.account}
              onEditHandler={onEditHandler}
              updateAccountCallback={updateAccountCallback}
              onPaginateNext={onPaginateNext}
              onPaginatePrev={onPaginatePrev}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

function ComponentPlaceholder() {
  return (
    <Row className="mb-4">
      <Col>
        <Card className="mb-2 text-center">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="w-25">
                <Placeholder xs={10} />
              </div>
              <div className="w-25 d-flex justify-content-end">
                <Placeholder.Button xs={3} aria-hidden="true" />
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <AccountKPI.Placeholder />
            <AccountTable.Placeholder />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

AccountCard.Placeholder = ComponentPlaceholder;

export default AccountCard;
