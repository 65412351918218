import { ChangeEvent, useCallback, useEffect, useReducer } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { IAccountCGDashboard, IAccountCG, IAccountCGTransactionForm } from '../../Types';
import ApiProvider from '../../ApiProvider';
import { DEFAULT_TABLE_SIZE } from '.';

type Props = {
  account?: IAccountCG;
  transactionToAddEdit?: IAccountCGTransactionForm;
  offset: number;
  callbackFunction: ({
    accountData,
    globalDashboardData,
  }: {
    accountData: IAccountCG;
    globalDashboardData: IAccountCGDashboard;
  }) => void;
  cancelCallbackFunction: () => void;
};

type State = {
  isProcessing: boolean;
  error: string | null;
  success: string | null;
  formData?: IAccountCGTransactionForm;
};

function AddEditTransactionForm({
  account,
  offset,
  transactionToAddEdit,
  callbackFunction,
  cancelCallbackFunction,
}: Props) {
  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({
      ...state,
      ...newState,
    }),
    {
      isProcessing: false,
      error: null,
      success: null,
    }
  );

  useEffect(() => {
    if (transactionToAddEdit) {
      setState({ formData: transactionToAddEdit });
    }
  }, [transactionToAddEdit]);

  const onCancelHandler = useCallback(() => {
    setState({
      formData: undefined,
      isProcessing: false,
      error: null,
      success: null,
    });
    cancelCallbackFunction();
  }, [cancelCallbackFunction]);

  const onFormInputHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
      let newValue = event.target.value;

      if (event.target.type === 'checkbox') {
        // @ts-ignore
        newValue = event.target.checked;
      }

      setState({
        formData: {
          ...state.formData,
          [event.target.name]: newValue,
        } as IAccountCGTransactionForm,
      });
    },
    [state.formData]
  );

  const handleFormSubmit = useCallback(
    async (transaction?: IAccountCGTransactionForm) => {
      if (transaction) {
        setState({ isProcessing: true, error: null });

        ApiProvider.addOrUpdateTransaction({
          transaction,
          offset: offset,
          limit: DEFAULT_TABLE_SIZE,
        })
          .then((response) => {
            setState({
              success: response.message,
            });
            callbackFunction(response.data);
          })
          .catch((err) => {
            setState({ error: err.message, isProcessing: false });
          })
          .finally(() => {
            setState({ isProcessing: false });
          });
      }
    },
    [offset, callbackFunction]
  );

  return (
    <Modal size="lg" backdrop="static" show={state.formData === undefined ? false : true} onHide={onCancelHandler}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit(state.formData);
        }}
      >
        <Modal.Header closeButton={!state.isProcessing}>
          {state.formData && account && (
            <Modal.Title>
              {state.formData.account_cg_transaction_id === null
                ? `${account.name} - Ajouter une transaction`
                : `${account.name} - Modifier une transaction`}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {state.error && <Alert variant="danger">{state.error}</Alert>}
          {state.success && (
            <Alert className="mb-0" variant="success">
              {state.success}
            </Alert>
          )}
          {state.formData && !state.success && (
            <>
              <Form.Group className="mb-3" controlId="interestToggle">
                <Form.Label>Intérêts ?</Form.Label>
                <Form.Check
                  type="switch"
                  name="interest"
                  disabled={state.isProcessing}
                  defaultChecked={state.formData.interest}
                  onChange={onFormInputHandler}
                  label="Oui"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="dateInput">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  required
                  type="date"
                  disabled={state.isProcessing}
                  onChange={onFormInputHandler}
                  name="date"
                  defaultValue={state.formData.date}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="montantInput">
                <Form.Label>Montant</Form.Label>
                <Form.Control
                  required
                  type="number"
                  step="any"
                  disabled={state.isProcessing}
                  onChange={onFormInputHandler}
                  name="amount"
                  value={state.formData.amount}
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={state.isProcessing}
            onClick={() => {
              onCancelHandler();
            }}
          >
            Fermer
          </Button>
          {!state.success && (
            <Button
              variant="primary"
              type="submit"
              className="d-flex flex-row gap-2 align-items-center"
              disabled={state.isProcessing}
            >
              {state.isProcessing ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span>Enregistrement...</span>
                </>
              ) : (
                'Enregistrer'
              )}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddEditTransactionForm;
