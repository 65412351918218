import { Col, Placeholder, Row } from 'react-bootstrap';
import { IAccountCNG } from '../../../Types';
import { CURRENCY_EURO_FORMAT, PERCENTAGE_FORMAT } from '../../../../../Types';

type Props = {
  account: IAccountCNG;
};

function AccountKPI({ account }: Props) {
  return (
    <Row xs={1} sm={2} lg={3} className="mb-3">
      <Col className="mb-3 text-primary text-center">
        <h3>Situation</h3>
        <h2>{CURRENCY_EURO_FORMAT.format(account.last_current_value)}</h2>
      </Col>
      <Col className="mb-3 text-info text-center">
        <h3>Investissement</h3>
        <h2>{CURRENCY_EURO_FORMAT.format(account.last_invested_amount)}</h2>
      </Col>
      <Col className={`mb-3 text-center ${account.last_return < 0 ? 'text-danger' : 'text-success'}`}>
        <h3>{account.last_return < 0 ? 'Moins Value' : 'Plus Value'}</h3>
        <h2>
          {CURRENCY_EURO_FORMAT.format(account.last_return)}
          {` `} ({PERCENTAGE_FORMAT.format(account.last_return_percentage)})
        </h2>
      </Col>
    </Row>
  );
}

function ComponentPlaceholder() {
  return (
    <Row xs={1} sm={2} lg={3} className="mb-3 gy-3 text-center">
      <Col className="text-primary">
        <h3>Situation</h3>
        <h2>
          <Placeholder xs={6} />
        </h2>
      </Col>
      <Col className="text-info">
        <h3>Investissement</h3>
        <h2>
          <Placeholder xs={6} />
        </h2>
      </Col>
      <Col className="text-success">
        <h3>Plus / Moins Value</h3>
        <h2>
          <Placeholder xs={6} />
        </h2>
      </Col>
    </Row>
  );
}

AccountKPI.Placeholder = ComponentPlaceholder;

export default AccountKPI;
